import { apiClient } from '@/custom_functions/api_client';

export default {
  namespaced: true,

  state: { campaign: null },

  getters: { getCampaign: (state) => state.campaign },

  actions: {
    createCampaign: async ({ commit }, data) => {
      const response = await apiClient.post('v2/campaigns', data);
      commit('updateCampaign', response.data.campaign);
    },

    fetchCampaign: async ({ commit }, campaignId) => {
      const response = await apiClient.get(`v2/campaigns/${campaignId}`);
      commit('updateCampaign', response.data.campaign);
    },

    updateCampaign: async ({ commit }, { campaignId, data }) => {
      const response = await apiClient.patch(
        `v2/campaigns/${campaignId}`,
        data,
      );
      commit('updateCampaign', response.data.campaign);
    },

    deleteCampaign: async ({ commit }, campaignId) => {
      await apiClient.delete(`v2/campaigns/${campaignId}`);
      commit('updateCampaign', null);
    },
  },

  mutations: {
    updateCampaign: (state, campaign) => {
      state.campaign = campaign;
    },
  },
};
