<template>
  <div>
    <div class="btn-group btn-group-sm mb-3">
      <button
        class="btn"
        :disabled="step.any_answer_accepted"
        :class="multipleSwitchClass(step.data.multipleAnswers)"
        @click="changeQuizeStepMultiple(false)"
      >
        Один
      </button>

      <button
        class="btn"
        :disabled="step.any_answer_accepted"
        :class="multipleSwitchClass(!step.data.multipleAnswers)"
        @click="changeQuizeStepMultiple(true)"
      >
        Несколько
      </button>
    </div>

    <draggable
      v-model:model-value.lazy="step.data.variants"
      item-key="id"
    >
      <template #item="{ element }">
        <div
          class="mb-3 card flex-row p-3 row align-items-center justify-content-end"
        >
          <div
            class="col-1 mb-3 d-flex align-items-center justify-content-around"
          >
            <font-awesome-icon
              icon="grip-vertical"
              class="text-muted drag-icon"
            />

            <input
              v-if="step.data.multipleAnswers"
              v-model="element.value"
              type="checkbox"
              :value="true"
              :disabled="step.any_answer_accepted"
            />

            <input
              v-else
              v-model="selected"
              type="radio"
              :value="element.id"
              :disabled="step.any_answer_accepted"
            />
          </div>

          <div class="col-11 flex-grow-1 d-flex align-items-center mb-3">
            <input
              v-model="element.text"
              class="form-control"
              placeholder="Вариант ответа"
              type="text"
            />

            <button
              class="ms-2 btn btn-link text-danger"
              @click.prevent="deleteVariant(element)"
            >
              <font-awesome-icon icon="xmark" />
            </button>
          </div>

          <div class="col-11 d-flex align-items-center">
            <button
              v-if="element.tip === null"
              class="btn btn-link p-0"
              @click.prevent="element.tip = ''"
            >
              <font-awesome-icon
                icon="plus"
                size="sm"
              />
              включить пояснение
            </button>

            <template v-else>
              <input
                v-model.lazy="element.tip"
                class="form-control"
                placeholder="Текст пояснения"
              />

              <button
                class="ms-2 btn btn-link text-danger"
                @click.prevent="element.tip = null"
              >
                <font-awesome-icon icon="xmark" />
              </button>
            </template>
          </div>
        </div>
      </template>
    </draggable>

    <button
      class="btn btn-outline-primary btn-sm"
      @click="addVariant"
    >
      + добавить вариант
    </button>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps({ step: { type: Object, required: true } });

const selected = ref(null);

const multipleSwitchClass = (value) => {
  return value ? 'btn-outline-secondary' : 'btn-primary';
};

const changeQuizeStepMultiple = (value) => {
  props.step.data.multipleAnswers = value;
};

const deleteVariant = (element) => {
  props.step.data.variants = props.step.data.variants.filter(
    (variant) => JSON.stringify(variant) !== JSON.stringify(element),
  );
};

const addVariant = () => {
  props.step.data.variants.push({
    id: uuidv4(),
    text: '',
    value: false,
    tip: null,
  });
};

watch(
  () => selected.value,
  (newSelected) => {
    props.step.data.variants.forEach((variant) => {
      variant.value = variant.id === newSelected;
    });
  },
);

watch(
  () => props.step,
  (newStep) => {
    if (newStep && newStep.data.multipleAnswers === false) {
      const selectedVariant = newStep.data.variants.find(
        (variant) => variant.value,
      );
      if (selectedVariant) selected.value = selectedVariant.id;
    }
  },
  { immediate: true },
);

if (props.step && props.step.data.multipleAnswers === false) {
  const selectedVariant = props.step.data.variants.find(
    (variant) => variant.value,
  );
  if (selectedVariant) selected.value = selectedVariant.id;
}
</script>

<style>
.drag-icon {
  cursor: grab;
}
</style>
