<template>
  <div
    v-if="currentCampaign"
    class="container"
  >
    <h2 class="mb-4">Редактирование промокодов</h2>

    <CampaignParams :campaign="currentCampaign" />

    <div class="mb-4">
      <CampaignCourses
        v-model:selectedCourses="selectedCourses"
        v-model:isForAllCourses="currentCampaign.for_all_courses"
        :courses="courses"
      />
    </div>

    <CampaignPromoStatistics :campaign="currentCampaign" />

    <div>
      <button
        class="btn btn-outline-primary me-3"
        @click.prevent="updateCampaign"
      >
        Сохранить
      </button>

      <button
        class="btn btn-outline-danger"
        @click.prevent="$bvModal.show('delete-campaign-modal')"
      >
        Удалить
      </button>

      <b-modal
        id="delete-campaign-modal"
        hide-footer
      >
        <template v-slot:modal-title>Подтвердите действие</template>
        <div class="d-block text-center">
          <h4>Кампания «{{ currentCampaign.name }}» будет удалена</h4>
        </div>
        <b-button
          class="mt-3 me-2"
          block
          variant="danger"
          @click="deleteCampaign"
        >
          Удалить
        </b-button>
        <b-button
          class="mt-3"
          block
          @click="$bvModal.hide('delete-campaign-modal')"
        >
          Отменить
        </b-button>
      </b-modal>
    </div>
  </div>

  <div
    v-else
    class="loader text-center"
  >
    <b-spinner />
  </div>
</template>

<script setup>
import CampaignCourses from '@/components/promo_codes/CampaignCourses';
import CampaignParams from '@/components/promo_codes/CampaignParams';
import CampaignPromoStatistics from '@/components/promo_codes/CampaignPromoStatistics';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { makeToast } from '../../custom_functions/custom_functions';

const route = useRoute();
const router = useRouter();
const store = useStore();

const campaign = computed(() => {
  return store.getters['campaigns/getCampaign'];
});

const currentCampaign = ref(null);
const selectedCourses = ref([]);

const init = async () => {
  await store.dispatch('campaigns/fetchCampaign', route.params.id);
  currentCampaign.value = campaign.value;
  selectedCourses.value = currentCampaign.value.courses.map(
    (course) => course.id,
  );

  if (!currentCampaign.value.for_all_courses) {
    store.dispatch('courses/fetchCoursesOmitPagination');
  }
};
init();

const courses = computed(() => {
  return store.getters['courses/getCoursesOmitPagination'];
});

const validateCampaign = () => {
  if (!currentCampaign.value.name || !currentCampaign.value.discount) {
    makeToast('Пожалуйста, заполните название и скидку для кампании', 'danger');
    return false;
  }

  if (
    currentCampaign.value.unit === 'rubles' &&
    currentCampaign.value.discount <= 0
  ) {
    makeToast('Размер скидки должен быть больше 0₽', 'danger');
    return false;
  }

  if (
    currentCampaign.value.unit === 'percents' &&
    (currentCampaign.value.discount <= 0 ||
      currentCampaign.value.discount > 100)
  ) {
    makeToast('Размер скидки должен быть в диапозоне от 1% до 100%', 'danger');
    return false;
  }

  return true;
};

const updateCampaign = async () => {
  if (!validateCampaign()) return;

  const data = {
    campaign: {
      ...currentCampaign.value,
    },
  };

  if (data.campaign.for_all_courses) {
    data.campaign.course_ids = [];
  } else {
    data.campaign.course_ids = selectedCourses.value;
  }

  await store.dispatch('campaigns/updateCampaign', {
    campaignId: route.params.id,
    data,
  });

  syncAfterUpdate();

  makeToast('Кампания отредактирована', 'primary');
};

const deleteCampaign = async () => {
  await store.dispatch('campaigns/deleteCampaign', route.params.id);
  await router.replace({
    name: 'promo_codes_index',
    params: { page: 1 },
  });
};

const syncAfterUpdate = () => {
  currentCampaign.value = campaign.value;
  selectedCourses.value = currentCampaign.value.courses.map(
    (course) => course.id,
  );
};
</script>
