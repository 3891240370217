<template>
  <div class="container">
    <h2 class="mb-4">Добавление промокодов</h2>

    <CampaignParams :campaign="campaign" />

    <CampaignCourses
      v-model:selectedCourses="selectedCourses"
      v-model:isForAllCourses="campaign.for_all_courses"
      :courses="courses"
      @updateCourses="update"
    />

    <!-- Promocodes -->
    <div class="mb-4">
      <h3 class="mb-3">Список промокодов</h3>

      <div class="row">
        <div class="col-8">
          <div class="mb-3">
            <label class="mb-2">Промокоды через пробел</label>

            <textarea
              v-model="promoCodes"
              class="form-control"
              rows="3"
            />
          </div>
        </div>
      </div>
    </div>

    <button
      type="submit"
      class="btn btn-primary"
      @click="createCampaign"
    >
      Сохранить
    </button>
  </div>
</template>

<script setup>
import CampaignParams from '@/components/promo_codes/CampaignParams';
import CampaignCourses from '@/components/promo_codes/CampaignCourses';
import { makeToast } from '@/custom_functions/custom_functions';
import { reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const router = useRouter();
const store = useStore();

const campaign = reactive({
  unit: 'percents',
  courses_count_for_activation: 0,
});
const selectedCourses = ref([]);
const promoCodes = ref('');

store.dispatch('courses/fetchCoursesOmitPagination');

const courses = computed(() => {
  return store.getters['courses/getCoursesOmitPagination'];
});

const createCampaign = async () => {
  if (!campaign.name || !campaign.discount) {
    return makeToast(
      'Пожалуйста, заполните название и скидку для кампании',
      'danger',
    );
  }

  if (campaign.unit === 'rubles' && campaign.discount <= 0) {
    return makeToast('Размер скидки должен быть больше 0₽', 'danger');
  }

  if (
    campaign.unit === 'percents' &&
    (campaign.discount <= 0 || campaign.discount > 100)
  ) {
    return makeToast(
      'Размер скидки должен быть в диапозоне от 1% до 100%',
      'danger',
    );
  }

  campaign.course_ids = selectedCourses.value;
  campaign.promo_codes_list = promoCodes.value.split(' ');

  let postData = {
    campaign,
  };

  await store.dispatch('campaigns/createCampaign', postData);

  await router.replace({
    name: 'promo_codes_index',
    params: { page: 1 },
  });

  makeToast('Кампания сохранена', 'primary');
};

const update = (courses) => {
  selectedCourses.value = courses;
};
</script>
